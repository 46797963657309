<!--
Public: Wrapper component for logging User click events to server.

Example:
  <log-click
    classes          = "text-decoration-none color-black font-1-point-2em"
    is-external-link = "true"
    href             = "<%= product.external_url %>"
    metric           = "/api/metrics/clicked-product"
    target           = "_blank"
    class            = "pointer-on-hover"
    value            = '{ "product_id": "<%= product.id %>" }'
  >
    <%= product.subject %>
  </log-click>
-->
<template>
  <a @click      = "handleClick"
     :class      = "[classes]"
     :target     = "target"
     :aria-label = "href">
    <slot></slot>
  </a>
</template>

<script>
  import { post }               from '../lib/async.js'
  import { present, toBoolean } from '../lib/utils.js'

  export default {
    props: {
      // Public: Classes to apply to link.
      classes:        { default: '' },
      // Public: Whether or not the link is external
      isExternalLink: { default: false },
      // Public: When present, submit data to following url.
      endpoint:       { default: null },
      // Public: When present, redirect to link after link clicked.
      href:           { default: null },
      // Public: `target` attribute for anchor tag. Allows links to open in new window.
      target:         { default: 'self' },
      // Public: JSON-formatted String of data to submit to notificationUrl.
      value:          { default: null }
    },

    methods: {
      // Internal: If there's a notification URL, send notification with the provided value.
      //           If there's an href, redirect.
      handleClick() {
        if (present(this.endpoint)) {
          post(this.endpoint, { value: this.value, user_agent: navigator.userAgent })
            .then(this.redirect())
        } else {
          this.redirect()
        }
      },

      // Internal: Follow the provided href.
      redirect() {
        let href = this.href

        if (present(href)) {

          // IF External - Ensure the URL starts with 'http://' or 'https://'
          //
          // Otherwise window.open will append onto the back of the current I4CP Url in their browser
          if (toBoolean(this.isExternalLink)) {
            if (!/^https?:\/\//i.test(href)) {
              href = 'http://' + href;
            }
          }

          // Default: Links open in same tab.
          if (this.target == 'self') {
            window.location.href = href
          }

          // External links should open in new tab.
          if (this.target == '_blank') {
            window.open(href)
          }
        }
      }
    }
  }
</script>
