<template>
  <div v-if="showBanner" :class="`d-flex justify-content-center p-3 pb-0 w-100 ${cssClass}`">
    <div class="d-flex align-items-center">
      <img :src="deviceLogo" :alt="deviceAlt" class="ui-h-50 mt-2" />
    </div>

    <div class="mt-4 me-2 color-nav-blue fs-6">
      <div>i4cp Insights Mobile App</div>
      <div>i4cp</div>
      <div>FREE - in the App Store</div>
    </div>

    <div>
      <div class="float-end mt-0">
        <a @click="close" class="">
          <i class="bi bi-x color-nav-blue fs-4 fw-bold"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon     from './icon.vue'
  import { post } from '../lib/async.js'
  import {
    blank,
    device,
    isMobile,
    toBoolean
  }               from '../lib/utils.js'

  export default {
    components: { Icon },

    props: {
      // Public: CSS wrapper class
      cssClass: { default: '' },
      // Public: Current user's ID.
      userId:   { default: null }
    },

    data() {
      return {
        // Public: Whether the user has closed the banner.
        bannerClosed: false,
        // Public: Navbar element
        navbar:       null,
      }
    },

    computed: {
      device:   device,
      isMobile: isMobile,

      // Internal: `alt` attribute, depending on the device.
      deviceAlt() {
        return `${this.device} logo`
      },

      // Internal: URL for device's app store logo.
      deviceLogo() {
        if (this.isAppleAppStore)   return 'https://content.i4cp.com/images/image_uploads/0000/3712/apple_app_store.png'
        if (this.isGooglePlayStore) return 'https://content.i4cp.com/images/image_uploads/0000/3713/google_play_store.png'
      },

      // Internal: Calculate URL for app store based on device.
      deviceStoreUrl() {
        if (this.isAppleAppStore)   return 'https://apps.apple.com/us/app/i4cp-insights/id1382247140'
        if (this.isGooglePlayStore) return 'https://play.google.com/store/apps/details?id=com.i4cp.Insights'

        return 'https://www.i4cp.com/mobile'
      },

      // Internal: Check local storage to see if User has previously dismissed banner.
      hasDismissed() {
        return localStorage.getItem('i4cp-pwa-banner')
      },

      // Internal: Return true if this is an apple device
      isAppleAppStore() {
        return /iOS|iPadOS/.test(this.device)
      },

      // Internal: Return true if this is an android device
      isGooglePlayStore() {
        return this.device == 'Android'
      },

      // Internal: Only show banner if on mobile device & User hasn't previously clicked out of it.
      showBanner() {
        return this.isMobile && !this.bannerClosed && !this.isGooglePlayStore
      }
    },

    methods: {
      // Public: Close popup, set as viewed, shift navbar to top.
      close() {
        this.bannerClosed = true

        this.setViewed()
      },

      // Internal: Log that the User has navigated to the app store
      logAppClick() {
        if (blank(this.userId)) return

        const body = {
          user_id:    this.userId,
          device:     this.device,
          user_agent: navigator.userAgent
        }

        post('/api/metrics/visited-app-store', body)
          .then(() => {
            this.close()
          })
      },

      // Public: Go to the store specified for the User's device.
      //         We want to make that the user has clicked the banner, so don't use an `href`
      navigateToStore() {
        this.setViewed()
        this.logAppClick()

        this.$nextTick(() => {
          window.location.href = this.deviceStoreUrl
        })
      },

      // Public: Add to local storage that User has dismissed the banner.
      setViewed() {
        localStorage.setItem('i4cp-pwa-banner', true)
      }
    },

    mounted() {
      if (!isMobile()) return

      // Don't render banner if User has previously clicked it
      if (toBoolean(this.hasDismissed)) {
        this.bannerClosed = true

        return
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/colors.scss";

  .mobile-app-banner {
    border-top: solid 1px $light-gray;
    /* border-left: solid 1px $light-gray;
       border-right: solid 1px $light-gray; */
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.5);
    z-index: 100000000 !important;
  }

  .description {
    border-radius: 10px 10px 0 0 ;
    border-left: solid 1px $light-gray;
    border-right: solid 1px $light-gray;
  }

  .instructions {
    border-top: solid 1px $light-gray;
    border-bottom: solid 1px $light-gray;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
  }

  .bottom-chevron {
    background-color: $lightest-gray;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    transform: rotate(45deg);
    border-right: solid 1px $medium-gray;
    border-bottom: solid 1px $medium-gray;
  }
</style>
