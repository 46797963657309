<!--
Scroll content contained in this element in from the left side of the screen.

<scroll-in-from-left>
  This content will slide in from the left side of the screen.
</scroll-in-from-left
-->
<template>
  <div class="scroll-in-from-left">
    <div :class="scrollClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      // Internal: Classes to be applied to the slide in region.
      scrollClasses() {
        return `scroll-text`
      }
    },
  }
</script>

<style scoped>
  .scroll-in-from-left {
    overflow: hidden;
  }

  .scroll-text {
    animation: scroll-in 1.5s cubic-bezier(0.45, 0.05, 0.55, 0.95);
  }

  @keyframes scroll-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
</style>
