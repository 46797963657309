<!--
  Show bio details for the specified user.
-->
<template>
  <a :href="peoplePage">
    <div class="marketing-person-tile pop-shadow">
      <!-- Avatar -->
      <div class="bio-avatar">
        <img :src="user?.bio_avatar_url"
             :alt="user?.first_name + ' ' + user?.last_name" />
      </div>

      <!-- Details  -->
      <div class="text-center">
        <div class="pt-4 mb-1 fs-4 fw-bold color-nav-blue ui-h-75px">
          {{user?.first_name}} {{user?.last_name}}
        </div>
        <div class="py-2 color-medium-gray ui-h-75px">
          {{user?.title}}
        </div>

        <!-- Conctact links -->
        <div class="links pb-4">
          <a v-if="user?.linkedin_profile_url"
             :href="user?.linkedin_profile_url"
             target="_blank"
             class="color-nav-blue">
            <i class="bi bi-linkedin pe-2"></i>
          </a>
          <a :href="`mailto:${user?.username}`"
             target="_blank"
             class="color-nav-blue">
            <i class="bi bi-envelope-fill ps-2"></i>
          </a>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
  import {mapState} from 'vuex';
  import {blank}    from '../lib/utils.js'
  import {get}      from '../lib/async.js'

  export default {
    props: {
      // Used to determine which user to show.
      username: { default: null }
    },

    data() {
      return {
        // Internal: Individual user details.
        user: null
      }
    },

    computed: {
      ...mapState(['bioData']),

      // Internal: Page path to view the person's full bio.
      peoplePage() {
        return this.user
                  ?.bio_url
                  ?.replace(/^\/?/, "/")
      }
    },

    mounted() {
      // Look up the user in the cached vuex data.
      this.user = this.bioData.find(user => user.username == this.username)
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/colors.scss";

  .marketing-person-tile {
    border-radius: 1em;
    margin: 2em;
  }

  .bio-avatar {
    border-radius: 1em 1em 0 0;
    overflow-y: hidden;
    height: 23em;
  }

  .bio-avatar img {
    width: 100%;
  }

  .links {
    font-size: 2.5em;
  }
</style>
