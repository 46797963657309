<template>
  <div :class="cssClass">
    <a  @click="expanded = !expanded"
        :class="expanderBarClasslist">
          Collection details
          <i :class="expanderBarIcon"></i>
    </a>
    <div v-if="expanded" class="background-color-lightest-gray p-3">
      <div v-html="displayedHtml">
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center px-4"
           v-if="toBoolean(isGuest)">
        <hr class="w-100 my-5" />
        <div class="color-nav-blue fw-bold text-center fs-4 mb-3">
          Read more content and access other resources from i4cp.
        </div>
        <div class="color-nav-blue d-block text-center mb-4 ui-fs-7">
          This content is available exclusively to i4cp members. Become an enterprise member to unlock these resources and
          <a class="fw-semibold" href="/solutions/membership/">other benefits</a> for your entire company.
        </div>
        <div class="w-50 mb-5">
          <a class="ui-dark-btn ui-md-btn pointer ui-fs-7"
             href="/contact">
              BECOME A MEMBER
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { truncateHtmlLines, toBoolean }   from '../lib/utils.js'

  export default {
    props: {
      // CSS classes to apply to the div.
      cssClass: { default: '' },
      // The full html to render.
      html:     { default: '' },
      // Boolean from Ruby regaring if the user can see the full dropdown or a guest version
      isGuest:  { default: true },
    },

    data: function() {
      return {
        // Public: Whether the component is expanded to show the full html.
        expanded: false
      }
    },

    computed: {
      // Public: The html displayed by the component.
      displayedHtml() {
        return toBoolean(this.isGuest) ? truncateHtmlLines(this.html, 11) : this.html
      },

      // Returns a classlist for the expander bar based on the state of the component
      expanderBarClasslist() {
        const baseClasslist = 'pointer fw-bold d-flex fs-6 py-3 px-3 justify-content-between align-items-center text-decoration-none'

        return this.expanded ? `${baseClasslist} text-white background-color-nav-blue` : `${baseClasslist} color-nav-blue background-color-light-secondary unexpanded-border`
      },

      // Classlist for the color changing bar's caret icon
      expanderBarIcon() {
        return this.expanded ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'
      }
    },

    methods: {
      toBoolean: toBoolean,
    }
  }
</script>

<style lang="scss" scoped>
  .unexpanded-border {
    border-style: solid;
    border-color: rgba(195,195,195,.5);
    border-width: 1px;
  }

</style>
