<!--
Expandable region with title - when clicked, scrolls content down into view.
-->
<template>
  <div class="expand-slide pointer" @click="toggle">
    <div class="fs-6 pb-2 fw-bold d-flex justify-content-between">
      {{ title }}
      <i :class="chevronClasses"></i>
    </div>

    <div ref="content" :style="contentStyle" class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // Title of the region
      title: { default: null }
    },

    data() {
      return {
        // Show the content region when true.
        show: false,
        // Capture the content height to determine total height needed.
        contentHeight: null
      }
    },

    computed: {
      // Internal: style used to animate the content.
      contentStyle() {
        return {
          height: this.show ? `${this.contentHeight}px` : '0px',
          overflow: 'hidden',
          transition: 'height 0.5s ease',
        }
      },

      // Internal: Chevron icon classes.
      chevronClasses() {
        return `bi ${this.show ? 'bi-chevron-up' : 'bi-chevron-down'}`
      }
    },

    methods: {
      // Internal: Toggle content visibility.
      toggle() {
        this.show = !this.show
      }
    },

    mounted() {
      this.contentHeight = this.$refs.content.scrollHeight

      addEventListener("resize", () => {
        this.contentHeight = this.$refs.content.scrollHeight
        console.log(this.contentHeight)
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/colors.scss";

  .expand-slide {
    border-bottom: solid 1px $medium-gray;
  }
</style>
