<!--
Show people associated with the given working group code. Only show people with the specified role
in that working group.
-->
<template>
  <div class="working-group-leads d-flex flex-wrap justify-content-center">
    <div v-for="user in users" class="px-4 py-3 text-center">
      <img class="ui-img-thumbnail-5 rounded-circle object-fit-cover"
           :src="imageUrl(user)"/>
      <div class="pt-3 text-center">
        <div class="ui-tx-3 fw-semibold color-light-primary">
          {{user.first_name}} {{user.last_name}}
        </div>
        <div v-if="toBoolean(showTitle)">{{user.title}}</div>
        <div v-if="toBoolean(showCompany)">{{user.company}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import {get}       from '../lib/async.js'
  import {toBoolean} from '../lib/utils.js'

  export default {
    props: {
      // Working group code (from the sections.code table/field)
      code:        { default: null },
      // Working group roles (chair, co-chair, lead-researcher, etc.)
      roles:       { default: '' },
      // Show each user's title if true
      showCompany: { default: true },
      // Show each user's title if true
      showTitle:   { default: true }
    },

    data() {
      return {
        // Internal: list of users.
        users: []
      }
    },

    methods: {
      toBoolean: toBoolean,

      // Internal: Use the bio url if available, otherwise the user's avatar.
      imageUrl(user) {
        return user.bio_avatar_url || user.avatar_url
      },

      // Internal: Get user data from the backend.
      getLeads() {
        get('/api/users/working-group-leads', {code: this.code, roles: this.roles})
          .then((users) => {
            this.users = users
          })
      }
    },

    mounted() {
      this.getLeads()
    }
  }
</script>

<style lang="scss" scoped>
  .working-group-leads img {
    height: 16rem;
    width: 16rem;
    border-radius: 3px;
  }
</style>
